<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "FinMonitoring",
    props: [
        'visible'
    ],
    mixins:[displaySettings],
    data() {
        return {
            isRefusal: false,
            confirmFinMonitoring: false,
        }
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            }
        }
    },
    watch: {
        confirmFinMonitoring(val) {
            if (val) {
                this.$emit('confirm', true);
                this.dialogVisible = false;
            }
        }
    }
}
</script>

<template>
<div class="fin-monitoring">
<!--    :before-close="handleClose"-->
    <el-dialog
        :visible.sync="dialogVisible"
        :width="isMobile ? '100%' : '50%'"
        center
        :show-close="false"
    >
        <div class="text-center form-group" v-if="!isRefusal">
            <div class="header-text">
                Для оформлення замовлень загальною вартістю вище <span>40 000 грн</span> необхідно надати документи для проходження фін. моніторингу. Дані документи необхідно передати вашому менеджеру в чаті або електронною поштою
            </div>
            <div class="mt-3 confirm">
                <div class="consent mb-2">Я погоджусь з умовами проходження фін.моніторингу</div>
                <el-row type="flex" :gutter="15" justify="center">
                    <el-col :span="isMobile ? 10 : 5" class="text-right">
                        <el-button class="w-100" type="primary" @click="confirmFinMonitoring = true">Так</el-button>
                    </el-col>
                    <el-col :span="isMobile ? 10 : 5" class="text-left">
                        <el-button class="w-100" @click="isRefusal = true">Ні</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="text-center form-group" v-else>
            <div class="header-text">
                Вибачте, укладання договору неможливе. <br>
                Для оформлення полісів вартістю вище <span>40 000 грн</span> страхова компанія зобов’язана провести фінансовий моніторинг клієнта. Це стандартна процедура, що відповідає вимогам законодавства і спрямована на запобігання фінансовим правопорушенням.
            </div>
            <div class="mt-3 confirm">
                <el-row type="flex" justify="center">
                    <el-col :span="isMobile ? 10 : 5">
                        <el-button class="w-100" @click="dialogVisible = false; isRefusal = false">Скасування</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-dialog>
</div>
</template>

<style scoped>
.fin-monitoring {
    .el-button--primary {
        background-color: #009BEB;
    }
    .el-button--default {
        border-color: #009BEB;
        color: #009BEB;
    }
    .consent {
        text-decoration: underline;
    }
    .header-text {
        font-weight: bold;
        font-size: 25px;
        line-height: 37px;
        color: #24282C;
        margin-bottom: 12px;
        text-align: center;
        word-break: auto-phrase;
        span {
            white-space: nowrap;
        }
    }
    .confirm {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #24282C;
        text-align: center;
    }
}
* >>> .el-dialog {
    border-radius: 0.3rem!important;
}
</style>
