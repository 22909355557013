<template>
  <div class="modal modal-container modal-transaction fade" id="modalResponseConfirmStatus" tabindex="-1" role="dialog"
       aria-labelledby="modalResponseConfirmStatus" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-md" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-header">
            <div class="modal-title">Увага!  При спробі оформлення полісу виникла помилка</div>
          </div>
          <div class="modal-container__content orders">
            <div class="scroll-content">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="info-block"  v-html="'Відповідь від модуля страхової компанії: '+dataResponse">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <button type="button" class="btn btn-orange" @click="goBack">Редагувати дані </button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
});

<script>
export default {
  props: ['dataResponse','dataProductType'],
  data: function () {
    return {
    }
  },
  created() {
  },

  mounted() {
  },

  methods: {
    goBack(){
      if(this.dataProductType == 'osago') {
        window.location.href = '/order-step';
      }else if(this.dataProductType == 'greencard'){
        window.location.href = '/greencard-steps';
      }else if(this.dataProductType == 'tourism'){
        window.location.href = '/tourism-steps';
      }else{
        return;
      }
    }
  }
}
</script>
<style>
.scroll-content{
  overflow: auto;
  overflow-x: hidden;
  max-height: 300px;
}
.modalResponseConfirmStatus{
  padding-right: 0;
}
button.close{
  padding: 10px;
  position: absolute;
  right: 0;
}
</style>
