<script>
import VueSlider from "vue-slider-component";
import {mapActions} from "vuex";

export default {
    name: "UseBonusPopover",
    props: ['showPopover'],
    components: {VueSlider},
    data() {
        return {
            paySum: 0,
        }
    },
    computed: {
        levels() {
            return this.$store.state.levelsStore.levels;
        },
        currentLevelId(){
            return this.$store.getters.USER_INFO.levelId;
        },
        maxSum() {
            return this.levels ? this.levels.find(item => item.id === this.currentLevelId)?.sum_use_bonuses : 0;
        }
    },
    methods: {
        ...mapActions('levelsStore', [
            'getLevels'
        ]),
        confirm() {
            if (this.paySum <= parseFloat(this.$store.getters.USER_INFO.bonusSystemBalance) && this.paySum >= 1 && this.maxSum >= this.paySum) {
                this.$emit('confirm', this.paySum)
                this.paySum = 0;
            }
        },
        formatCurrency() {
            // Удаляем все символы, кроме цифр и "."
            this.paySum = this.paySum.replace(/[^0-9.,]/g, '');

            this.paySum = this.paySum.replace(',','.');
            // Удаляем все точки, кроме первой
            const parts = this.paySum.split('.');
            if (parts.length >= 2) {
                this.paySum =parts[0] + '.' + parts[1].substring(0, 2)
            }
        },
        cancel() {
            this.paySum = 0;
            this.$emit('cancel');
        }
    },
    mounted() {
        this.getLevels()
    }
}
</script>

<template>
  <div class="use-bonus-popover">
      <el-popover
          placement="bottom"
          width="350"
          trigger="manual"
          v-model="showPopover">
          <div class="m-4">
              <h1>
                  Скористатись бонусами
                  <img src="~@/assets/images/icons/ukasko-bonus.svg" alt="">
              </h1>
              <h3>Вкажіть бажану суму бонусів</h3>

              <el-row>
                  <div class="form-group">
                      <input v-model="paySum" type="text" @input="formatCurrency" :min="1" placeholder="сумма"
                        class="form-control"
                      >
<!--                      :class="['form-control', {invalid: ($v.paySum.$error)}]"-->

                      <vue-slider v-model.lazy="paySum"
                                  tooltip="none"
                                  :max="maxSum"
                                  :interval="5">
                      </vue-slider>
                      <small class="error" v-if="paySum < 1">Cума повинна бути більше 1 грн</small>
                      <small class="error" v-else-if="paySum > maxSum">Cума повинна бути меньше {{maxSum}} грн</small>
                      <small class="error" v-else-if="paySum > parseFloat(this.$store.getters.USER_INFO.bonusSystemBalance)">Недостатьно коштів</small>
<!--                      <small class="error" v-else-if="$v.paySum.$dirty && !$v.paySum.required ">Введіть бажану суму</small>-->
<!--                      <small class="error" v-else-if="(($v.paySum.$dirty && !$v.paySum.minLength) || ($v.paySum.$dirty && !$v.paySum.maxLength)) ">Введіть коректну суму</small>-->
                  </div>
              </el-row>

              <el-row>
                  <el-col>
                      <el-button class="w-100" type="success" @click="confirm">Підтвердити</el-button>
                  </el-col>
                  <el-col>
                      <el-button class="w-100" @click="cancel">Скасувати</el-button>
                  </el-col>
              </el-row>
          </div>
      </el-popover>
  </div>
</template>

<style scoped>
.use-bonus-popover {
    h1 {
        color: #24282C;
        font-size: 20px;
    }
    .el-row {
        .el-col {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
