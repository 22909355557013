<script>
import useBonusPopover from "@/components/app/bonusSystem/UseBonusPopover.vue";

export default {
    name: "UseBonus",
    components: {useBonusPopover},
    data() {
        return {
            showEarnings: false,
            useBonuses: false,
            errorMessage: null,
        }
    },
    computed: {
        bonusSystemBalance() {
            return parseFloat(this.$store.getters.USER_INFO.bonusSystemBalance)
        },
        amountBalance() {
            return this.$store.state.transactionsStore.all.amount;
        },
    },
    methods: {
        changeShowEarnings() {
            this.showEarnings = !this.showEarnings
            this.$emit('show-earnings', this.showEarnings)
        },
        changeSwitch(value) {
            if (this.amountBalance < 0) {
                this.useBonuses = false
                this.errorMessage = 'Використовувати бонуси можна тільки за умови позитивного балансу в гаманці'
            }else if (this.bonusSystemBalance === 0) {
                this.useBonuses = false
                this.errorMessage = 'На вашому балансі відсутні бонуси для списання'
            } else {
                this.$emit('use-bonuses', value)
            }
        }
    },
    mounted() {
        $('[data-toggle="tooltip"]').tooltip({
            html: true
        });
    }
}
</script>

<template>
    <el-row type="flex" justify="end" align="middle">
        <el-col :span="12" class="pointer">
            <div @click="changeShowEarnings">
                <img v-if="!showEarnings"
                     src="~@/assets/images/icons/eye-gray.svg"
                     alt=""
                >
                <img v-else
                     src="~@/assets/images/icons/eye-gray-slash.svg"
                     alt=""
                >
                Детальна інформація
            </div>
        </el-col>
        <el-col :span="12" v-if="$store.getters.USER_INFO.levelId">
            <el-switch v-model="useBonuses"
                       active-text="Додати бонуси"
                       active-color="#50A64D"
                       @change="changeSwitch"
            >
            </el-switch>
            <i
                data-placement="bottom"
                data-toggle="tooltip"
                title=""
                class="icon question ml-2"
                style="margin-bottom: -6px"
                data-original-title="За правилами бонусної системи додавання бонусів відбувається на максимальний розмір списання допустимий для вашого рівня. Якщо баланс бонусних балів меньший за максимальний розмір списання, то розмір використаних бонусів буде на сумму доступного балансу"
            ></i>
            <br>
            <span class="error" v-if="errorMessage">{{ errorMessage }}</span>
        </el-col>
        <el-col :span="12">
            <div></div>
        </el-col>
    </el-row>
</template>

<style scoped>
.pointer {
    cursor: pointer;
}
* >>> .el-switch__label.is-active {
    color: #24282C;
}
.error {
    color: red;
    font-size: 14px;
    font-weight: 500;
}
</style>
