<script>
import useBonusPopover from "@/components/app/bonusSystem/UseBonusPopover.vue";
import {mapActions} from "vuex";

export default {
    name: "Earnings",
    components: {useBonusPopover},
    props: [
        'useBonuses',
        'earnings',
        'value',
        'dataIsLoadEarning',
    ],
    data() {
        return {
            showPopover: false,
            errorMessage: null,
            isLoadEarning: false,
        }
    },
    computed: {
        sumEarnings() {
            return (parseFloat(this.earnings) + parseFloat(this.value)).toFixed(2)
        },
        amountBalance() {
            return this.$store.state.transactionsStore.all.amount;
        },
        bonusValue() {
            let bonusSystemBalance = this.$store.getters.USER_INFO.bonusSystemBalance
            let sumUseBonuses = this.levels ? this.levels.find(item => item.id === this.currentLevelId)?.sum_use_bonuses : 0;
            return bonusSystemBalance < sumUseBonuses ? bonusSystemBalance : sumUseBonuses;
        },
        levels() {
            return this.$store.state.levelsStore.levels;
        },
        currentLevelId() {
            return this.$store.getters.USER_INFO.levelId;
        },
    },
    watch: {
        dataIsLoadEarning: function(newValue, oldVAlue){
          this.isLoadEarning = newValue;
        },
        useBonuses: function (newValue, oldValue) {
            if (newValue) {
                this.showPopover = false
                this.$emit('input', this.bonusValue)
            } else {
                this.$emit('input', 0)
            }
        }
    },
    methods: {
        ...mapActions('levelsStore', [
            'getLevels'
        ]),
        showUseBonusPopover() {
            if (this.amountBalance > 0) {
                this.showPopover = !this.showPopover
            } else {
                this.useBonuses = false
                this.errorMessage = 'Використовувати бонуси можна тільки за умови позитивного балансу в гаманці'
            }
        }
    },
    mounted() {
        this.isLoadEarning = this.dataIsLoadEarning;
        this.getLevels()
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-3">
            <div class="info-block">
                <b>Ваш заробіток:</b>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="earnings" v-loading="isLoadEarning">
                <p>{{ sumEarnings }} грн</p>
            </div>
        </div>
        <div class="col-lg-12 row">
        </div>
        <div class="col-lg-3" v-if="value">
            <div class="bonus-block">
                <b>Комісійна винагорода</b>
            </div>
        </div>
        <div class="col-lg-3" v-if="value">
            <div class="commission-earnings">
                <p>{{ earnings }} грн</p>
            </div>
        </div>
        <div class="col-lg-12 row">
        </div>
        <div class="col-lg-3" v-if="value">
            <div class="bonus-block">
                <b>Бонусний заробіток</b>
            </div>
        </div>
        <div class="col-lg-3" v-if="value">
            <div class="bonus-earnings">
                <p>{{ value }} грн</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.earnings {
    font-size: 30px;
    color: #24282C;
    font-weight: bold;
    text-align: left;
}

.bonus-block {
    color: #999DA6;
    font-size: 15px;
}

.commission-earnings {
    font-weight: 700;
    font-size: 20px;
    color: #24282C;
}

.bonus-earnings {
    font-weight: 700;
    font-size: 20px;
    color: #50A64D;
}
</style>
